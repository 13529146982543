/**
 * Const element
 * Iframe Lost Password component
 * Display the iframe of Lost Password
 */
/* Import section */
import React from "react"

/* Declaration function */
const Iframelostpassword= () => {
  /** NEW VERSION ON DEV27 */
  //var src = 'https://dev27.myca.io/hhweb/apps/authentication/#lostPasswordPortalConnect'

  /** OLD VERSION ON PROD */
  //var src = 'https://platform.hellohealth.com/login/?stylesheet=patientportal#lostPassword'

  /** NEW VERSION ON PROD */
  var src = 'https://platform.hellohealth.com/hhweb/apps/authentication/#lostPasswordPortalConnect'

  return (
    <iframe className="iframe-lostpwd" name="lostPassword" src={src} scrolling="no" title="lost-password"></iframe>
  )
}

/* Export function */
export default Iframelostpassword
