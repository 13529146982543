/**
 * Const element
 * Iframe Lost Password Confirm component
 * Display the iframe of Lost Password Confirm
 */
/* Import section */
import React, { Component } from "react"
import PropTypes from "prop-types"

/* Declaration function */
class Iframelostpasswordconfirm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      keyLostPwd: this.props.lpKey,
      srcLostPwd: ''
    }
  }

  componentDidMount () {
    (this.state.keyLostPwd !== undefined) ? (
      this.setState({
        keyLostPwd: this.props.lpKey,
        srcLostPwd: 'https://platform.hellohealth.com/hhweb/apps/authentication/#lostPasswordConfirmPortalConnect?lpKey='+ this.state.keyLostPwd
      })
    ) : (
      this.setState({
        keyLostPwd: this.props.lpKey,
        srcLostPwd: 'https://platform.hellohealth.com/hhweb/apps/authentication/#lostPasswordConfirmPortalConnect'
      })
    )
  }


  render () {
    //console.log('key : ' + this.state.keyLostPwd)
    /** NEW VERSION ON PROD */
    var src = this.state.srcLostPwd
    //console.log('src : ' + src)

    return (
      <iframe className="iframe-lostpwdconfirm" name="lostpwdconfirm" src={src} scrolling="no" title="lostpasswordconfirm" />
    )
  }
  /** NEW VERSION ON DEV27 */
  //var src = 'https://dev27.myca.io/hhweb/apps/authentication/#lostPasswordConfirmPortalConnect'

  /** OLD VERSION ON PROD */
  //var src = 'https://platform.hellohealth.com/login/?stylesheet=patientportal#lostPasswordConfirm'

}

/* Function propTypes declaration */
Iframelostpasswordconfirm.propTypes = {
  lpKey: PropTypes.string
}

/* Export function */
export default Iframelostpasswordconfirm
