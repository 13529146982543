/**
 * Lost Password page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Geturlparameter from "../components/geturlparameter"
import Iframelostpassword from "../components/iframelostpassword"
import Iframelostpasswordconfirm from "../components/iframelostpasswordconfirm"
import Layoutwithoutfooter from "../components/layoutwithoutfooter"
import Seo from "../components/seo"
import "../styles/pages/lost-password.scss"

/* Page function declaration */
const LostpasswordPage = ({ data, search }) => {
  const { lpKey } = search
  return (
    <Layoutwithoutfooter>
      <Seo
        title={data.wpPage.seo.title}
        description={data.wpPage.seo.metaDesc}
        classbody="no-cookies"
        noindex="noindex"
      />

      {/* Iframe content */}
      <section className="section-lostpwd iframe-section">
        <h1 className="title-level-1">{ lpKey ? ("Reset your password" ) : ( "Lost Password") }</h1>
        { lpKey ? (
            <Iframelostpasswordconfirm lpKey={lpKey} />
        ) : (
            <Iframelostpassword />
        )}
      </section>
    </Layoutwithoutfooter>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "lost-password"}) {
      seo {
        metaDesc
        title
      }
    }
  }
`

/* Export page informations */
export default Geturlparameter(LostpasswordPage)
